
import { defineComponent } from 'vue'
import pageHead from '../components/page-head.vue'
import tabCapsule from '@/components/tab-capsule.vue'
import { getPlanList, PlanDto, openPlan } from '@/api/insurance'

export default defineComponent({
    name: 'InsuranceNew',
    components: {
        'page-head': pageHead,
        'tab-capsule': tabCapsule
    },
    data () {
        return {
            type: ['直投保单', '灵活保单', '日日保'],
            typeActive: 0,
            data: [] as PlanDto[]
        }
    },
    created () {
        this.getPlanList()
    },
    methods: {
        onInsuranceTab (index: number) {
            this.typeActive = index
            this.getPlanList()
        },
        // 获取方案列表
        async getPlanList () {
            const result = await getPlanList({
                type: ['10', '10', '30'][this.typeActive],
                supplier: ['渤海', '现代财', ''][this.typeActive]
            })
            this.data = result
        },
        async onOpenPlan (id: string) {
            await this.$util.confirm('开通方案', '是否确认开通该方案')
            await openPlan({
                planId: id
            })
            this.$message.success('方案开通成功，已为您跳转到方案列表！')
            this.$router.push({
                path: '/insurance/plan'
            })
        }
    }
})
